@import "../../variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.homepage-featured-products-block-container {
    background-color: $white;

    & + .button-container {
        display: none;
    }
}

.homepage-featured-products-block-wrapper {
    padding: 24px 0 48px 24px;
    gap: 24px;

    @include media-breakpoint-up(md) {
        padding: 80px 0;
        gap: 80px;
        margin: 0 80px;
        max-width: 1400px;
    }
}

.homepage-featured-products-block-title-wrapper {
    padding-right: 24px;

    @include media-breakpoint-up(md) {
        padding-right: 0;
    }
}

.homepage-featured-products-block-title {
    color: $dark-green;
    font-family: $font-qualion;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;

    @include media-breakpoint-up(md) {
        font-size: 32px;
        line-height: 42px;
    }
}

.homepage-featured-products-block-link {
    color: $dark-green;
    font-family: $font-qualion;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-decoration: none !important;
    border-bottom: 2px solid $dark-green;

    &:hover,
    :focus {
        color: $light-green-tertiary;
        border-color: $light-green-tertiary;
        opacity: 0.5;
    }
}

.homepage-featured-products-block-product-range {
    color: $subdued-green;
    font-family: $font-qualion;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media-breakpoint-up(md) {
        font-size: 14px;
        line-height: 20px;
    }
}

.homepage-featured-products-block-product-name {
    color: $dark-green;
    font-family: $font-qualion;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 36px;
        -webkit-line-clamp: 1;
    }
}

.homepage-featured-products-block-product-description {
    color: $neutral-70;
    font-family: $font-qualion;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media-breakpoint-up(md) {
        color: $neutral-80;
        font-size: 16px;
        line-height: 24px;
    }
}

.homepage-featured-products-block-product-link {
    color: $dark-green;
    font-family: $font-qualion;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-decoration: none !important;
    border-bottom: 1px solid $dark-green;

    &:hover,
    :focus {
        color: $light-green-tertiary;
        border-color: $light-green-tertiary;
        opacity: 0.5;
    }

    @include media-breakpoint-up(md) {
        font-size: 16px;
        line-height: 24px;
    }
}

.homepage-featured-products-block-product-image-wrapper {
    height: 150px;
    border-radius: 8px;

    @include media-breakpoint-up(md) {
        height: 327px;
        border-radius: 24px;
    }
}

.homepage-featured-products-block-product-image {
    max-height: 100px;

    @include media-breakpoint-up(md) {
        max-height: 270px;
        max-width: 250px;
    }

    &.full-bleed {
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
        border-radius: 8px;
        height: 100%;

        @include media-breakpoint-up(md) {
            border-radius: 24px;
        }
    }
}

.homepage-featured-products-block-product {
    max-width: 150px;
    gap: 8px;

    @include media-breakpoint-up(md) {
        max-width: 327px;
    }
}
